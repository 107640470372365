import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import App from "./client/App";
import "./styles/font.css";
import "react-loading-skeleton/dist/skeleton.css";

import "swiper/swiper-bundle.min.css";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

const appStart = async () => {
  console.log("앱 실행 완료");
};

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);
