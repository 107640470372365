import styled from "@emotion/styled";
import React from "react";
import { useHistory } from "react-router-dom";
import { Title, Text } from "./PopupComponents";

const LoginPopup = ({ onBack }) => {
  const history = useHistory();

  return (
    <PopupWrapper>
      <PopupArea>
        <Title>로그인</Title>
        <Text>
          로그인이 필요한 페이지입니다.
          <br />
          바로 로그인하시겠습니까?
        </Text>
        <PopupButtonArea>
          <BackButton
            type="button"
            onClick={() => {
              if (onBack) onBack();
              else history.goBack();
            }}
          >
            계속 둘러보기
          </BackButton>
          <LoginButton
            onClick={() => {
              history.push("/root?iframe=true");
            }}
          >
            로그인
          </LoginButton>
        </PopupButtonArea>
      </PopupArea>
    </PopupWrapper>
  );
};

export default LoginPopup;

const BackButton = styled.button`
  height: 38px;
  margin-right: 25px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  color: #77797c;
  background: none;
  border: 0;
`;

const LoginButton = styled.button`
  width: 90px;
  height: 38px;
  border-radius: 5px;
  background-color: #4e98ed;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
`;

const PopupButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

const PopupArea = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #fff;
  padding: 26px 20px 20px;
`;

const PopupWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(15, 15, 15, 0.4);
  padding: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 10;
`;
