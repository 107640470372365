const primary = {
  font: "#ffffff",
  bg: "#4e98ed",
};

const color = {
  primary: "#4e98ed",
  danger: "#fb4d44",
  white: "#ffffff",
  black: "#434446",
  black_bold: "#0f0f0f",
  black_three: "#212223",
  gray: "#9d9fa2",
  gray_two: "#77797c",
  gray_three: "#d2d3d5",
};

const container = "0 18px";

const theme = {
  primary,
  color,
  container,
};

export default theme;
