import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { useHistory } from "react-router-dom";
import auth from "../../utils/auth";
import { Title, Text } from "./PopupComponents";

const OtherLoginPopup = ({ display }) => {
  const history = useHistory();
  return (
    <PopupWrapper displayType={display}>
      <PopupArea>
        <Title>다른 기기에서 로그인</Title>
        <Text>
          다른 기기에서 로그인되었습니다.
          <br />
          재로그인을 위해 로그인 페이지로 이동합니다.
        </Text>
        <PopupButtonArea>
          <LoginButton
            onClick={() => {
              window.localStorage.clear();

              if (!auth.getLogin()) {
                history.push("/root?iframe=true");
                window.location.reload();
              }
            }}
          >
            로그인
          </LoginButton>
        </PopupButtonArea>
      </PopupArea>
    </PopupWrapper>
  );
};

export default OtherLoginPopup;

const LoginButton = styled.button`
  width: 90px;
  height: 38px;
  border-radius: 5px;
  background-color: #4e98ed;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
`;

const PopupButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

const PopupArea = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #fff;
  padding: 26px 20px 20px;
`;

const PopupWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(15, 15, 15, 0.4);
  padding: 25px;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  display: none;

  ${({ displayType }) =>
    displayType &&
    css`
      display: flex;
    `}
`;
