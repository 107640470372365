import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import Preview from "../pages/landing/Preview";
import { api } from "../utils/api";
import auth from "../utils/auth";
import LoginPopup from "./popup/LoginPopup";
import OtherLoginPopup from "./popup/OtherLoginPopup";
import useSWR from "swr";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let pcDevice = "win16|win32|win64|mac|macintel";
  const history = useHistory();

  const { data } = useSWR(
    auth.getToken() !== null &&
      auth.getToken() !== "null" &&
      auth.getToken() !== undefined &&
      auth.getToken() !== "undefined" &&
      "/account/check"
  );

  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (auth.getLogin() && window.location.href.indexOf("/landing") < 0) {
      setPopup(true);
    } else {
      setPopup(false);
    }

    if (
      auth.getToken() !== null &&
      auth.getToken() !== "null" &&
      auth.getToken() !== undefined &&
      auth.getToken() !== "undefined"
    ) {
      handleCheck();
    }
  }, []);

  const handleCheck = async () => {
    const res = await api.get("/mypage/profile");
    // localStorage.setItem("user_id", res?.data?.data?.user_id);
    if (res?.data?.code === "SA0098") {
      localStorage.setItem("social_login", false);
      history.push("/join");
    } else {
      localStorage.removeItem("social_login");
    }
  };

  useEffect(() => {
    if (data) {
      handleCheck2();
    }
  }, [data]);

  const handleCheck2 = () => {
    if (data && data?.type) {
      if (data?.type !== "use_limit") {
        history.push("/login_check");
      }
    } else {
      localStorage.removeItem("limit");
    }
  };

  window.addEventListener(
    "storage",
    () => {
      if (auth.getLogin() && window.location.href.indexOf("/landing") < 0) {
        setPopup(true);
      } else {
        setPopup(false);
      }
    },
    false
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        pcDevice.indexOf(navigator.platform.toLowerCase()) >= 0 ? (
          auth.getToken() !== null &&
          auth.getToken() !== "null" &&
          auth.getToken() !== undefined &&
          auth.getToken() !== "undefined" ? (
            <Preview>
              <Component {...props} />
              <OtherLoginPopup display={popup} />
            </Preview>
          ) : (
            <Preview>
              <Component {...props} />
              <LoginPopup />
            </Preview>
          )
        ) : auth.getToken() !== null &&
          auth.getToken() !== "null" &&
          auth.getToken() !== undefined &&
          auth.getToken() !== "undefined" ? (
          <>
            <Component {...props} />
            <OtherLoginPopup display={popup} />
          </>
        ) : (
          <>
            <Component {...props} />
            <LoginPopup />
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
