import Axios from "axios";
import moment from "moment";
import auth from "./auth";

export const endpoint = process.env.REACT_APP_API_KEY;

export const api = Axios.create({
  baseURL: endpoint,
  headers: {
    Authorization: auth.getToken() ? `Bearer ${auth.getToken()}` : "",
  },
});
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config._retry
    ) {
      if (auth.getToken() || auth.getTokenTime()) {
        if (
          auth.getTokenTime() && auth.getTokenTime().indexOf("-") > -1
            ? moment().isBefore(moment(auth.getTokenTime()))
            : moment().isBefore(moment().add(auth.getTokenTime(), "seconds"))
        ) {
          if (!auth.getLogin() && !auth.getSocial()) {
            auth.setLogin("login");
            window.location.reload();
          }
        } else {
          if (auth.getRefreshToken()) {
            const {
              data: { success, data, alert },
            } = await api.post("/refresh/token", {
              refresh_token: auth.getRefreshToken(),
            });

            if (success) {
              auth.setToken(data?.access_token);
              auth.setRefreshToken(data?.refresh_token);
              window.location.reload();
            } else {
              auth.clear("login");
              auth.clear("token");
              auth.clear("tokentime");
              auth.clear("refreshToken");
              if (!window.location.href.includes("?share=true")) {
                window.location.href = "/root?iframe=true";
              }
            }
          } else {
            auth.clear("login");
            auth.clear("token");
            auth.clear("tokentime");
            auth.clear("refreshToken");
            if (!window.location.href.includes("?share=true")) {
              window.location.href = "/root?iframe=true";
            }
          }
        }
      }
    }
    return Promise.reject(error);
  }
);
