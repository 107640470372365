import styled from "@emotion/styled";

export const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #212223;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.2px;
  color: #434446;
  margin-top: 14px;
  white-space: break-spaces;
`;
