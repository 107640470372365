const { parse } = JSON;
const auth = {
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
    return null;
  },
  clearAppStorage() {
    return localStorage.clear();
  },
  get(key) {
    return parse(localStorage.getItem(key));
  },
  setToken(token) {
    return localStorage.setItem("token", token);
  },
  getToken() {
    return localStorage.getItem("token");
  },
  setTokenTime(time) {
    return localStorage.setItem("tokentime", time);
  },
  getTokenTime() {
    return localStorage.getItem("tokentime");
  },
  setLogin(login) {
    return localStorage.setItem("login", login);
  },
  getLogin() {
    return localStorage.getItem("login");
  },
  setBlock(block) {
    return localStorage.setItem("block", block);
  },
  getBlock() {
    return localStorage.getItem("block");
  },
  setCart(cart) {
    return localStorage.setItem("cart", cart);
  },
  getCart() {
    return localStorage.getItem("cart");
  },
  setCode(code) {
    return localStorage.setItem("code", code);
  },
  getCode() {
    return localStorage.getItem("code");
  },
  setRefreshToken(token) {
    return localStorage.setItem("refreshToken", token);
  },
  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  },
  getSocial() {
    return localStorage.getItem("social_login");
  },
};
export default auth;
