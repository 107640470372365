import loadable from "@loadable/component";

export const Splash = loadable(() => import("./Splash"));

export const Component = loadable(() => import("./Component"));

export const Root = loadable(() => import("./Root"));
export const Landing = loadable(() => import("./landing/Landing"));
export const Preview = loadable(() => import("./landing/Preview"));

export const Join = loadable(() => import("./user/Join"));
export const Login = loadable(() => import("./user/Login"));
export const LoginCheck = loadable(() => import("./user/LoginCheck"));
export const LoginRedirect = loadable(() => import("./user/LoginRedirect"));
export const OnBoarding = loadable(() => import("./user/OnBoarding"));

export const AppLoginNaver = loadable(() => import("./user/AppLoginNaver"));
export const AppLoginKakao = loadable(() => import("./user/AppLoginKakao"));
export const AppLoginFacebook = loadable(() =>
  import("./user/AppLoginFacebook")
);
export const AppLoginApple = loadable(() => import("./user/AppLoginApple"));

export const Search = loadable(() => import("./Search"));
export const Alarm = loadable(() => import("./Alarm"));
export const Cart = loadable(() => import("./Cart"));

export const Payment = loadable(() => import("./payment/Payment"));
export const PaymentRedirect = loadable(() => import("./payment/Redirect"));

export const PostSada = loadable(() => import("./post/SadaPost"));
export const BringPost = loadable(() => import("./post/BringPost"));
export const SendPost = loadable(() => import("./post/SendPost"));
export const PostDream = loadable(() => import("./post/DreamPost"));

export const BringEdit = loadable(() => import("./edit/BringEdit"));
export const SendEdit = loadable(() => import("./edit/SendEdit"));
export const DreamEdit = loadable(() => import("./edit/DreamEdit"));

export const Main = loadable(() => import("./dream-market/Main"));
export const List = loadable(() => import("./dream-market/List"));
export const PlanList = loadable(() => import("./dream-market/PlanList"));
export const Detail = loadable(() => import("./dream-market/Detail"));
export const InquiryList = loadable(() => import("./dream-market/InquiryList"));
export const OrderList = loadable(() => import("./dream-market/OrderList"));

export const Market = loadable(() => import("./dream-market/Market"));
export const MarketDetail = loadable(() =>
  import("./dream-market/MarketDetail")
);
export const RegisteredProduct = loadable(() =>
  import("./dream-market/RegisteredProduct")
);
export const Notice = loadable(() => import("./dream-market/Notice"));
export const NoticeDetail = loadable(() =>
  import("./dream-market/NoticeDetail")
);
export const ReceivedScore = loadable(() =>
  import("./dream-market/ReceivedScore")
);
export const ReceivedReview = loadable(() =>
  import("./dream-market/ReceivedReview")
);

export const DreamTalkList = loadable(() => import("./dream-talk/ChatList"));
export const DreamTalk = loadable(() => import("./dream-talk/DreamTalk"));
export const DreamTalkDetail = loadable(() => import("./dream-talk/Detail"));
export const TransactionInfo = loadable(() =>
  import("./dream-talk/TransactionInfo")
);

export const MainSadadream = loadable(() =>
  import("./sadadream/MainSadadream")
);
export const MannerReviewDetail = loadable(() =>
  import("./sadadream/MannerReviewDetail")
);
export const MainGuide = loadable(() => import("./sadadream/MainGuide"));
export const SadaDream = loadable(() => import("./sadadream/SadaDream"));
export const SadaDetail = loadable(() => import("./sadadream/Detail"));

export const ProfileEdit = loadable(() => import("./mypage/ProfileEdit"));
export const Order = loadable(() => import("./mypage/Order"));

export const Delivery = loadable(() => import("./mypage/Delivery"));
export const DeliverySelect = loadable(() => import("./Delivery"));
export const DeliveryDetail = loadable(() => import("./Delivery/Detail"));

export const DirectDeal = loadable(() => import("./dream-talk/DirectDeal"));
export const DealingCancel = loadable(() =>
  import("./dream-talk/DealingCancel")
);
export const TalkDealReview = loadable(() => import("./dream-talk/Review"));

export const Mypage = loadable(() => import("./mypage/Mypage"));
export const MyShop = loadable(() => import("./mypage/MyShop"));
export const RegisterPro = loadable(() => import("./mypage/RegisterPro"));
export const MyShopBadge = loadable(() => import("./mypage/myShop/Badge"));
export const ReceivedScore_mypage = loadable(() =>
  import("./mypage/ReceivedScore_mypage")
);
export const MypageNotice = loadable(() => import("./mypage/notice/Notice"));
export const MypageNoticeDetail = loadable(() =>
  import("./mypage/notice/NoticeDetail")
);
export const NoticeRegister = loadable(() =>
  import("./mypage/notice/Register")
);
export const FreeDelivery = loadable(() => import("./mypage/FreeDelivery"));
export const PickList = loadable(() => import("./mypage/PickList"));
export const Keyword = loadable(() => import("./mypage/Keyword"));
export const Coupon = loadable(() => import("./mypage/coupon"));
export const CouponQR = loadable(() => import("./mypage/coupon/QR"));
export const CouponSubmit = loadable(() => import("./mypage/coupon/Submit"));

export const Setting = loadable(() => import("./mypage/setting"));

export const SettingAccount = loadable(() =>
  import("./mypage/setting/account")
);
export const SettingSocialAccount = loadable(() =>
  import("./mypage/setting/account/SocialAccount")
);
export const SettingAccountWithdraw = loadable(() =>
  import("./mypage/setting/account/Withdraw")
);
export const WithdrawDetail = loadable(() =>
  import("./mypage/setting/account/WithdrawDetail")
);
export const BlackList = loadable(() => import("./mypage/setting/BlackList"));
export const Event = loadable(() => import("./mypage/setting/event"));
export const EventDetail = loadable(() =>
  import("./mypage/setting/event/Detail")
);

export const Service = loadable(() => import("./mypage/setting/service"));
export const Terms = loadable(() => import("./mypage/setting/service/Terms"));
export const MyPageAlarm = loadable(() => import("./mypage/setting/alarm"));
export const Customer = loadable(() => import("./mypage/setting/customer"));
export const CustomerQna = loadable(() =>
  import("./mypage/setting/customer/qna")
);
export const QnaDetail = loadable(() =>
  import("./mypage/setting/customer/qna/Detail")
);
export const QnaSubmit = loadable(() =>
  import("./mypage/setting/customer/qna/Submit")
);
export const Often = loadable(() => import("./mypage/setting/customer/Often"));

export const TalkDealReviewDetail = loadable(() =>
  import("./dream-talk/ReviewDatail")
);

export const ReviewComplete = loadable(() =>
  import("./dream-talk/ReviewComplete")
);

export const PhoneRedirect = loadable(() =>
  import("./mypage/setting/account/PhoneRedirect")
);

export const Transport = loadable(() => import("./dream-talk/Transport"));

export const AccountSubmit = loadable(() =>
  import("./dream-talk/AccountSubmit")
);

export const OrderReviewComplete = loadable(() =>
  import("./mypage/ReviewComplete")
);

export const OrderReviewDetail = loadable(() =>
  import("./mypage/ReviewDatail")
);

// 브랜드 관
export const BrandList = loadable(() => import("./brand/List"));
export const KeywordList = loadable(() => import("./sadadream/KeywordList"));

// 럭키드로우
export const LuckydrawDetail = loadable(() =>
  import("./lucky/LuckydrawDetail")
);
export const LuckyPayment = loadable(() => import("./lucky/LuckyPayment"));
export const LuckyComplete = loadable(() => import("./lucky/LuckyComplete"));
export const MypageLuckyDetail = loadable(() =>
  import("./lucky/MypageLuckyDetail")
);
