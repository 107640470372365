import styled from "@emotion/styled";
import React from "react";

import Logo1 from "../../assets/images/preview_icon_1.png";
import Logo2 from "../../assets/images/preview_icon_2.png";
import Logo3 from "../../assets/images/preview_icon_3.gif";
import Logo4 from "../../assets/images/preview_icon_4.png";
import Logo5 from "../../assets/images/preview_icon_5.png";
import Logo6 from "../../assets/images/preview_icon_6.png";
import AppleIcon from "../../assets/images/apple_playstore_icon.png";
import GoogleIcon from "../../assets/images/google_playstore_icon.png";

const Preview = ({ children }) => {
  const handleAppStore = () => {
    window.app_report_conversion();
    window.open(
      "https://apps.apple.com/kr/app/사다드림-sadadream/id1600223042"
    );
  };
  const handleGooglePlay = () => {
    window.google_report_conversion();
    window.open(
      "https://play.google.com/store/apps/details?id=kr.sadadream.app"
    );
  };

  return (
    <Wrapper>
      <Content>
        <SadaDreamBox>
          <img src={Logo1} alt="image" className="sadadream_logo" />

          <div className="image_contents">
            <img src={Logo2} alt="image" />
            <img src={Logo3} alt="image" className="logo_gif" />
            <img src={Logo4} alt="image" />
            <img src={Logo5} alt="image" className="logo_icon" />
            <img src={Logo6} alt="image" className="logo_icon2" />
          </div>
          {/* <Charactor src={Chrac} alt="image" /> */}
          {/* <SadaText>
            국내·해외 구분하지 말고 구매 대행은 사다드림에서! <br />
            구매대행의 시작, 사다드림을 모바일앱에서 만나보세요!
          </SadaText> */}
          <Download>
            <button type="button" onClick={handleGooglePlay} />
            <button type="button" onClick={handleAppStore} />
          </Download>
        </SadaDreamBox>
        <PreviewArea>{children}</PreviewArea>
      </Content>
    </Wrapper>
  );
};

export default Preview;

const PreviewArea = styled.div`
  width: 420px;
  min-width: 420px;
  height: 100%;
  border: 0;
  background: #fff;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);

  @media (max-width: 1024px) {
    width: 100%;
    min-width: 0px;
  }
`;

const Download = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 410px;
  width: 100%;

  & button {
    max-width: 171px;
    width: calc(50% - 5px);
    height: 55px;
    background-repeat: no-repeat;
    background-size: contain;

    &:nth-of-type(2) {
      margin-left: 10px;
    }
  }
  & button:first-of-type {
    background-image: url(${GoogleIcon});
    background-position-x: right;
  }
  & button:last-of-type {
    background-image: url(${AppleIcon});
  }
`;

const SadaText = styled.div`
  font-size: 16px;
  line-height: 2;
  letter-spacing: -0.2px;
  text-align: center;
  color: #434446;
  margin: 16px 0 45px;
`;

const Charactor = styled.img`
  width: 250px;
`;

const SadaDreamBox = styled.div`
  max-width: 560px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;

  & .sadadream_logo {
    position: absolute;
    left: 8%;
    top: 20px;
  }

  & .image_contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & img.logo_gif {
      max-width: 379px;
    }
    & img.logo_icon {
      margin: 40px 0 47px;
    }
    & img.logo_icon2 {
      margin-bottom: 34px;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Content = styled.div`
  max-width: 980px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #fafafa;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
`;
