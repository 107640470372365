import styled from "@emotion/styled";
import React from "react";

const Loading = () => {
  return (
    <LoadingWra>
      <div>
        <img
          src={require("../assets/images/loading.gif").default}
          alt="loading"
        />
      </div>
    </LoadingWra>
  );
};

const LoadingWra = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding: 24px 19px;
    background: #fff;
    box-shadow: 3px 3px 10px 1px rgba(34, 34, 37, 0.1);

    & > img {
      width: 18px;
    }
  }
`;

export default Loading;
